import { Image, Box, Anchor } from "grommet";
import React from "react";
import { useThemeMode } from "src/hooks/themeSwitcherHook";

interface PropsNoRecord {
  w?: string;
  h?: string;
  fontSize?: string;
  pb?: string;
  pt?: string;
  img?: string;
  title?: string;
  subTitle?: string;
  linkHref?: string;
  linkText?: string;
  imgUrl?: string;
  widthText?: any;
}

const NoRecord: React.FC<PropsNoRecord> = ({
  w,
  h,
  pb,
  pt,
  fontSize,
  title,
  subTitle,
  widthText,
  linkHref = "https://docs.posichain.org",
  linkText = "Here",
}) => {
  const isDark = useThemeMode() === "dark";
  const imgNoReCord = isDark
    ? require("../../assets/no-record-dark.svg").default
    : require("../../assets/no-record-light.svg").default;

  return (
    <Box
      style={{ width: "100%" }}
      pad={{ top: pt || "0px", bottom: pb || "0px" }}
    >
      <Box direction={"column"} margin={{ horizontal: "auto" }}>
        <Image
          margin={{ horizontal: "auto" }}
          src={imgNoReCord}
          style={{ width: w || "200px", height: h || "200px" }}
        />
        <Box
          align="center"
          justify="center"
          style={{ fontSize: fontSize || "24px" }}
          margin={{ top: "15px" }}
        >
          {title || "No Records Found"}
        </Box>
        <Box
          style={{ width: widthText || "", fontSize: "14px" }}
          align="center"
          margin={{ horizontal: "auto", top: "5px" }}
          color={"gray.500"}
          as="span"
        >
          {subTitle || "There is nothing here!"}
          <Anchor
            href={linkHref}
            label={linkText}
            style={{ display: "contents" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NoRecord;
