import {AbiInput} from "web3-utils";
import {getAddress} from "src/utils";
import Big from "big.js";

export const convertInputs = (inputs: string[], abiInputs: AbiInput[]) => {
  return inputs.map((value, idx) => {
    console.log('abiInputs[idx].type', abiInputs[idx].type)
    switch (abiInputs[idx].type) {
      case "address":
        return getAddress(value).basicHex;
      case "uint256":
        return new Big(value || 0).toFixed();
      default:
        return value;
    }
  });
};
