export type ShardID = 0 | 1 | 2 | 3;

export type BlockHexNumber = string;
export type BlockHash = string;
export type BlockNumber = number;

export type LogsBloom = string;

export type TokenType = "ERC20" | "ERC1155" | "ERC721";

export type RPCBlockPosiChain = {
  difficulty: string;
  extraData: string;
  gasLimit: string;
  gasUsed: string;
  hash: BlockHash;
  logsBloom: LogsBloom;
  miner: string;
  mixHash: string;
  nonce: string;
  number: BlockHexNumber;
  parentHash: string;
  receiptsRoot: string;
  sha3Uncles: string | null;
  size: string;
  stateRoot: string;
  timestamp: string;
  transactions: RPCTransactionPosiChain[];
  stakingTransactions: RPCStakingTransactionPosiChain[];
  transactionsRoot: string;
  uncles: string[];
  epoch: string;
  viewID: string;
};

type Modify<T, R> = Omit<T, keyof R> & R;

export type Block = Modify<
  RPCBlockPosiChain,
  {
    number: string;
    epoch: string;
    difficulty: string;
    gasLimit: string;
    gasUsed: string;
    nonce: string;
    size: string;
    shardNumber?: number;
  }
>;

export type Address = string;
export type AddressPosiChain = string;

export type TransactionHash = string;
export type TransactionPosiChainHash = string;

export type RPCTransaction = {
  blockHash: BlockHash;
  blockNumber: BlockHexNumber;
  from: Address;
  to: Address;
  gas: string;
  gasPrice: string;
  hash: TransactionHash;
  input: ByteCode;
  nonce: string;
  r: string;
  s: string;
  timestamp: string;
  transactionIndex: string;
  v: string;
  value: string;
};

export type RPCTransactionPosiChain = {
  blockHash: BlockHash;
  blockNumber: BlockHexNumber;
  from: AddressPosiChain;
  to: AddressPosiChain;
  gas: string;
  gasPrice: string;
  gasLimit: string;
  gasUsed?: string;
  hash: TransactionPosiChainHash;
  ethHash: TransactionHash;
  input: ByteCode;
  nonce: string;
  r: string;
  s: string;
  shardID: ShardID;
  timestamp: string;
  toShardID: ShardID;
  transactionIndex: string;
  v: string;
  value: string;
  error?: string;
};
export type StakingTransactionType =
  | "CreateValidator"
  | "EditValidator"
  | "CollectRewards"
  | "Undelegate"
  | "Delegate";

export type RPCStakingTransactionPosiChain = {
  type: StakingTransactionType;
  blockHash: BlockHash;
  blockNumber: BlockHexNumber;
  from: AddressPosiChain;
  to: AddressPosiChain;
  gas: string;
  gasPrice: string;
  gasLimit: string;
  hash: TransactionPosiChainHash;
  input: ByteCode;
  nonce: string;
  r: string;
  s: string;
  shardID: ShardID;
  timestamp: string;
  toShardID: ShardID;
  transactionIndex: string;
  v: string;
  msg: any; // todo
  amount?: string;
};

export type Topic = string;
export type ByteCode = string;

export type Log = {
  address: Address;
  topics: Topic[];
  data: ByteCode;
  blockNumber: BlockHexNumber;
  transactionHash: TransactionHash;
  transactionIndex: string;
  blockHash: BlockHash;
  logIndex: string;
  removed: boolean;
};

export interface LogDetailed extends Log {
  input: string;
  timestamp: string;
}

export type TraceCallTypes =
  | "CALL"
  | "STATICCALL"
  | "CREATE"
  | "CREATE2"
  | "DELEGATECALL";

// how to extract see explorer-dashboard
export type TraceCallErrorToRevert = string;

export type InternalTransaction = {
  error?: TraceCallErrorToRevert;
  index: number;
  from: Address;
  to: Address;
  gas: string;
  gasUsed: string;
  input: ByteCode;
  output: ByteCode;
  time: string;
  type: TraceCallTypes;
  value: string;
  blockHash: BlockHash;
  blockNumber: BlockNumber;
  transactionHash: TransactionHash;
  signatures?: any[];
};

export type Transaction = {
  posiChain: RPCTransactionPosiChain;
  eth: RPCTransaction;
};

export enum TransactionExtraMark {
  normal = "normal",
  hasInternalPOSITransfers = "hasInternalONETransfers",
}

export interface RelatedTransaction {
  transactionType: RelatedTransactionType;
  address: string;
  blockNumber: string;
  hash: string;
  from: string;
  to: string;
  value: string;
  timestamp: string;
  type?: string;
  msg?: { amount: string; delegatorAddress: string; validatorAddress: string };
  amount?: string;
  input: string;
  gas: string;
  gasPrice: string;
  extraMark: TransactionExtraMark;
}

export type RelatedTransactionType =
  | "transaction"
  | "internal_transaction"
  | "stacking_transaction";

export interface AddressDetails {
  creator_address: string;
  solidityVersion: string;
  ipfs_hash?: string;
  meta?: { name?: string; image?: string };
  bytecode: string;
  IPFSHash?: string;
}

export interface IHexSignature {
  hash: string;
  signature: string;
}

// tokenAmount is erc20 token amount approved (allowance)
// tokenId is any specific token approved for this txn (or null if not approved)
// NOTE: tokenId of 0 may be valid, check undefined/null status!!
export interface ApprovalDetails {
  hash: string;
  lastUpdated: Date;
  assetAddress: string;
  spender: string;
  allowance: string;
  action: string;
  account: string;
  contract: string;
  tokenAmount?: number;
  tokenId?: number;
  type: TokenType;
  isFullApproval?: boolean;
}

export interface StakingDelegationResponse {
  Undelegations: Array<{ Amount: string; Epoch: string }>;
  amount: string;
  delegator_address: string;
  reward: string;
  validator_address: string;
}
