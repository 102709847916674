import React, {useEffect, useState} from 'react'
import useAPIPolling, {APIPollingOptions} from './polling'
import {singletonHook} from 'react-singleton-hook';

const url = `${process.env.REACT_APP_EXCHANGE_API_URL}statistics/POSI`
const fetchFunc = () => fetch(url).then(r => r.json())

export const usePOSIExchangeRate = singletonHook({}, () => {
  const [data, setData] = useState<any>({})

  const options: APIPollingOptions<any> = {
    fetchFunc,
    initialState: {},
    delay: 30000,
    disableTabListener: true
  }
  const res = useAPIPolling(options)
  useEffect(() => {
    const {price, price_change_rate} = res?.data || {price: 0, price_change_rate: 0};

    const response = {
      lastPrice: Number(price),
      priceChangePercent: Number(price_change_rate)
    }

    setData(response)
  }, [res])

  return data
})
// lastPrice, priceChangePercent