import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { IndexedDbStore, loadFromIndexedDB } from "../utils/indexedDB";
import { isTokenBridged } from "../utils";
import { useHistory } from "react-router-dom";

const initValue: ERC1155_Pool = {};

let globalSetMode = () => {
  return {};
};

let globalSetLoadingGetERC1155Pool = () => {
  return false;
}

export const useIsLoadingGetERC1155Pool = singletonHook(false, () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  //@ts-ignore
  globalSetLoadingGetERC1155Pool = setLoading;
  return isLoading;
})

export const useERC1155Pool = singletonHook(initValue, () => {
  const getPool = async () => {
    try {
      setLoadingERC1155Pool(true);
      const erc1155 = await loadFromIndexedDB(IndexedDbStore.ERC1155Pool)
      const erc1155Map = {} as Record<string, ERC1155>;
      erc1155.forEach(item => {
        erc1155Map[item.address] = {
          ...item,
          isBridged: isTokenBridged(item.address)
        };
      })
      setMode(erc1155Map);
      setLoadingERC1155Pool(false);
    } catch (e) {
      console.error('Cannot get ERC1155 records: ', (e as Error).message)
    }
  }
  const history = useHistory();
  useEffect(() => {
    getPool()
  }, [history])

  const [mode, setMode] = useState<ERC1155_Pool>(initValue);
  //@ts-ignore
  globalSetMode = setMode;
  return mode;
});

export const setERC1155Pool = (pool: ERC1155_Pool) => {
  //@ts-ignore
  globalSetMode(pool);
};

export const setLoadingERC1155Pool = (isLoading: boolean) => {
  //@ts-ignore
  globalSetLoadingGetERC1155Pool(isLoading);
};

export interface ERC1155 {
  name: string;
  address: string;
  totalSupply: string;
  holders: string;
  decimals: number;
  symbol: string;
  meta?: any;
  isBridged: boolean;
}

export type ERC1155_Pool = Record<string, ERC1155>;
