/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Box, Heading } from "grommet";
import { BaseContainer, SearchInput } from "src/components/ui";
import { useHistory, useLocation } from "react-router-dom";
import { ConfigureButton } from "./ConfigureButton";
import { useThemeMode } from "src/hooks/themeSwitcherHook";
import { CSSProperties } from "styled-components";
import DropdownMenuItem from "./DropdownMenuItem";
import { useMediaQuery } from "react-responsive";

const HeaderLine = (props: any) => {
  //@ts-ignore
  const isDark = useThemeMode() === "dark";

  return (
    <Box
      tag="header"
      direction="row"
      justify="center"
      background={isDark ? "majorText" : "headerText"}
      pad={{ vertical: "small" }}
      elevation={isDark ? "none" : "medium"}
      style={{ zIndex: "1" }}
      {...props}
    />
  );
};

export function AppHeader(props: { style: CSSProperties }) {
  const history = useHistory();
  const isDark = useThemeMode() === "dark";
  const isSmallerThan1200 = useMediaQuery({ maxDeviceWidth: "1200px" });
  const isSmallerThan400 = useMediaQuery({ maxDeviceWidth: "400px" });
  useEffect(() => {
    window.localStorage.setItem("currency", "ETH");
  }, []);
  const { pathname } = useLocation();

  const isMain = pathname === "/";
  const isDisplaySearch = !isMain && !isSmallerThan1200;

  return (
    <HeaderLine
      {...props}
      style={{ boxShadow: "0px 4px 8px rgb(0 0 0 / 12%)" }}
    >
      <BaseContainer direction="row" align="center" justify="between" flex>
        <Heading
          level="5"
          margin="none"
          style={{
            cursor: "pointer",
            color: "#fff",
          }}
          onClick={() => history.push("/")}
        >
          <Box direction={"row"} align={"center"}>
            <img
              src={
                isDark
                  ? require(`../../assets/${
                      isSmallerThan400 ? "logo_posi" : "LogoDark"
                    }.svg`).default
                  : require(`../../assets/${
                      isSmallerThan400 ? "logo_posi" : "Logo"
                    }.svg`).default
              }
            />
          </Box>
        </Heading>
        <Box
          style={{
            maxWidth: "60%",
            width: "60%",
            display: isDisplaySearch ? "flex" : "none",
          }}
        >
          <SearchInput verticalPad={"none"} />
        </Box>
        <Box direction="row">
          <Box
            direction="row"
            align={"center"}
            style={{ alignItems: "center" }}
          >
            <DropdownMenuItem
              title={"Tokens"}
              listDropdown={[
                {
                  label: "PRC20 tokens",
                  value: "/prc20",
                },
                {
                  label: "PRC721 tokens",
                  value: "/prc721",
                },
                {
                  label: "PRC1155 tokens",
                  value: "/prc1155",
                },
              ]}
            />
            <DropdownMenuItem
              title={"Tools"}
              listDropdown={[
                {
                  label: "Token Approvals",
                  value: "/tools/approvals",
                },
                {
                  label: "Check PRC",
                  value: "/tools/checkPrc",
                },
              ]}
            />
            <ConfigureButton />
          </Box>
        </Box>
      </BaseContainer>
    </HeaderLine>
  );
}
