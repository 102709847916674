import React, { useCallback, useEffect, useState } from "react";
import { Address, BaseContainer, BasePage } from "../../components/ui";
import { Text } from "grommet";
import { useHistory, useParams } from "react-router-dom";
import { getTraceLog } from "../../api/3rdPartyApi";
import AceEditor from "react-ace";
import { useThemeMode } from "../../hooks/themeSwitcherHook";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-cloud9_night";
import "ace-builds/src-noconflict/theme-cloud9_day";

const TraceLogPage: React.FC = () => {
  const { shardId, txId } = useParams<{ txId: string; shardId: string }>();

  const [traceData, setTraceData] = useState("");

  const history = useHistory();

  const handleGetTraceLog = useCallback(async () => {
    if (!!shardId && !!txId) {
      const traceData = await getTraceLog(shardId, txId);
      if (!!traceData) {
        setTraceData(JSON.stringify(JSON.parse(traceData), null, "\t") || "");
      } else {
        setTraceData("[]");
      }
    } else {
      history.push("/");
    }
  }, [shardId, txId]);

  useEffect(() => {
    handleGetTraceLog();
  }, [handleGetTraceLog]);

  const colorMode = useThemeMode();

  const [editorAce, setEditorAce] = useState<any>(null);
  //
  useEffect(() => {
    // @ts-ignore
    if (ace) {
      try {
        // @ts-ignore
        const editor = ace.edit("editor-content");
        setEditorAce(editor);
      } catch (e) {}
    }
  }, []);

  return (
    <BaseContainer pad={{ horizontal: "0" }}>
      <BasePage>
        <Text size={"medium"} style={{ fontWeight: "500" }}>
          Geth VM Trace Transaction
        </Text>
        <Text size={"small"} style={{ fontWeight: "400" }}>
          GETH Trace for Txn Hash{" "}
          <Address type={"tx"} address={txId} noHistoryPush hideCopyBtn />
        </Text>

        <Text size={"medium"} style={{ fontWeight: "500", marginTop: "16px" }}>
          Raw traces
        </Text>
        {!!traceData && (
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            name={`editor-content`}
            style={{ width: "100%" }}
            theme={colorMode === "light" ? "cloud9_day" : "cloud9_night"}
            // onLoad={this.onLoad}
            onChange={() => {}}
            fontSize={12}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={traceData || ""}
            setOptions={{
              useWrapMode: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
              readOnly: true,
            }}
          />
        )}
      </BasePage>
    </BaseContainer>
  );
};

export default TraceLogPage;
