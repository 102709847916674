import React, { FunctionComponent, useState } from "react";
import {
  IHexSignature,
  Log,
  RPCStakingTransactionPosiChain,
  RPCTransactionPosiChain,
} from "src/types";
import { tokenTransfersERC20 } from "./tokenTransfer/tokenTransfersERC20";
// import {tokenTransfersERC721} from './tokenTransfer/tokenTransfersERC721'
import { TokenTransfersERC1155 } from "./tokenTransfer/tokenTransfersERC1155";

import {
  transactionDisplayValues,
  transactionPropertyDescriptions,
  transactionPropertyDisplayNames,
  transactionPropertySort,
} from "./helpers";
import {
  CalculateFee,
  CalculateTransactionFee,
  formatNumber,
  TipContent,
} from "src/components/ui";
import { Anchor, Box, Text, Tip } from "grommet";
import { TransactionSubType } from "src/components/transaction/helpers";

import { CaretDownFill, CaretUpFill, CircleQuestion } from "grommet-icons";
import { TxStatusComponent } from "../ui/TxStatusComponent";
import { useMediaQuery } from "react-responsive";
import { useThemeMode } from "../../hooks/themeSwitcherHook";

const getColumns = ({ type = "" }) => [
  {
    property: "key",
    render: (e: any) => (
      <div key={`key-comp-${e.key}`}>
        <Tip
          dropProps={{ align: { left: "right" } }}
          content={
            <TipContent
              message={
                transactionPropertyDescriptions[e.key + type] ||
                transactionPropertyDescriptions[e.key]
              }
            />
          }
          plain
        >
          <span>
            <CircleQuestion size="small" />
          </span>
        </Tip>
        &nbsp;
        {transactionPropertyDisplayNames[e.key + type] ||
          transactionPropertyDisplayNames[e.key] ||
          e.key}
      </div>
    ),
    size: "1/3",
  },
  {
    property: "value",
    size: "2/3",
    render: (e: any) => e.value,
  },
];

type TransactionDetailsProps = {
  internalTxs?: any[];
  transaction: RPCTransactionPosiChain | RPCStakingTransactionPosiChain;
  inputSignature?: IHexSignature;
  type?: TransactionSubType;
  stakingData?: boolean;
  logs?: Log[];
  errorMsg: string | undefined;
  shorMoreHide?: boolean;
};

type tableEntry = {
  key: string;
  value: any;
};

// todo move out to a service to support any custom ABI

const tokenTransfers = (logs: Log[]) => {
  return (
    <>
      {tokenTransfersERC20(logs)}
      {TokenTransfersERC1155(logs)}
    </>
  );
};

const GasUsed = (props: {
  tx: RPCTransactionPosiChain | RPCStakingTransactionPosiChain;
}) => {
  const {
    tx: { gas, gasLimit },
  } = props;
  if (gas && gasLimit) {
    const percent = ((+gas / +gasLimit) * 100).toFixed(2);
    return (
      <Box direction={"row"} gap={"12px"}>
        <Text size={"small"}>{formatNumber(+gasLimit)}</Text>
        <Text size={"small"} color={"grey"}>
          |
        </Text>
        <Text size={"small"}>
          {formatNumber(+gas)} ({percent}%)
        </Text>
      </Box>
    );
  }
  return <>{gas}</>;
};

export const TransactionDetails: FunctionComponent<TransactionDetailsProps> = ({
  transaction,
  type,
  logs = [],
  errorMsg,
  shorMoreHide,
  stakingData,
  internalTxs = [],
  inputSignature,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const isSmallerThan900 = useMediaQuery({ maxDeviceWidth: "900px" });
  const newTransaction = {
    Status:
      errorMsg === undefined ? (
        +transaction.shardID > 0 ? (
          <TxStatusComponent msg={""} />
        ) : (
          <> </>
        )
      ) : (
        <TxStatusComponent msg={errorMsg} />
      ),
    ...transaction,
    tokenTransfers: tokenTransfers(logs),
    transactionFee: (
      <Box justify="center">{CalculateTransactionFee(transaction)}</Box>
    ),
    gasUsed: (
      <Box justify="center">
        <GasUsed tx={transaction} />
      </Box>
    ),
    gasPrice: <Box justify="center">{CalculateFee(transaction)}</Box>,
  };

  const keys = Object.keys(newTransaction).filter((key) => {
    if (stakingData) {
      return (
        ["tokenTransfers", "transactionFee", "gasPrice", "Status"].indexOf(
          key
        ) === -1
      );
    } else {
      return key !== "gas" && key !== "extraMark";
    }
  });
  const sortedKeys = keys
    .sort((a, b) => transactionPropertySort[b] - transactionPropertySort[a])
    .filter((k) => showDetails || ["r", "s", "v"].indexOf(k) === -1);

  const txData = sortedKeys.reduce((arr, key) => {
    // @ts-ignore
    const value = transactionDisplayValues(
      // @ts-ignore
      newTransaction,
      key,
      // @ts-ignore
      newTransaction[key],
      type,
      internalTxs,
      inputSignature
    );
    if (value === undefined) {
      return arr;
    }
    if (key !== "hash_harmony") {
      arr.push({ key, value } as tableEntry);
    }
    return arr;
  }, [] as tableEntry[]);
  const value = internalTxs;
  const colorMode = useThemeMode();
  return (
    <>
      <Box
        flex
        align="start"
        justify="start"
        style={{ overflow: "auto", flexDirection: "column", width: "100%" }}
      >
        {!!txData?.length &&
          txData?.map((item: tableEntry) => (
            <Box
              key={item.key}
              flex
              style={{
                justifyContent: "space-between",
                width: "100%",
                flexDirection: isSmallerThan900 ? "column" : "row",
                padding: "16px 10px",
                borderTop:
                  colorMode === "light"
                    ? "1px solid #e2e8f0"
                    : "1px solid #293440",
              }}
            >
              <div
                style={{
                  width: isSmallerThan900 ? "100%" : "30%",
                  fontWeight: "600",
                }}
                key={`key-comp-${item.key}`}
              >
                <Tip
                  dropProps={{ align: { left: "right" } }}
                  content={
                    <TipContent
                      message={
                        transactionPropertyDescriptions[item.key + type] ||
                        transactionPropertyDescriptions[item.key]
                      }
                    />
                  }
                  plain
                >
                  <span>
                    <CircleQuestion size="small" />
                  </span>
                </Tip>
                &nbsp;
                {transactionPropertyDisplayNames[item.key + type] ||
                  transactionPropertyDisplayNames[item.key] ||
                  item.key}
              </div>
              <div
                style={{
                  width: isSmallerThan900 ? "100%" : "70%",
                  marginTop: isSmallerThan900 ? "10px" : "0px",
                  wordBreak: "break-all",
                }}
              >
                {item?.value}
              </div>
            </Box>
          ))}

        {/*<DataTable*/}
        {/*  className={"g-table-body-last-col-right g-table-no-header"}*/}
        {/*  style={{ width: "100%", minWidth: "698px" }}*/}
        {/*  columns={getColumns({ type })}*/}
        {/*  data={txData}*/}
        {/*  step={10}*/}
        {/*  border={{*/}
        {/*    header: {*/}
        {/*      color: "none",*/}
        {/*    },*/}
        {/*    body: {*/}
        {/*      color: "border",*/}
        {/*      side: "top",*/}
        {/*      size: "1px",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*/>*/}
      </Box>

      {shorMoreHide ? null : (
        <Box align="center" justify="center" style={{ userSelect: "none" }}>
          <Anchor
            color={"brand"}
            onClick={() => setShowDetails(!showDetails)}
            margin={{ top: "medium" }}
          >
            {showDetails ? (
              <>
                Show less&nbsp;
                <CaretUpFill size="small" />
              </>
            ) : (
              <>
                Show more&nbsp;
                <CaretDownFill size="small" />
              </>
            )}
          </Anchor>
        </Box>
      )}
    </>
  );
};
