import React, {useEffect, useState} from "react";
import {Box, Text} from "grommet";
import {Link} from "react-router-dom";
import {usePopper} from 'react-popper';
import {useThemeMode} from "../../hooks/themeSwitcherHook";
import {darkTheme, palette, theme} from "../../theme";

interface IToolsButton {
  title: string;
  listDropdown: { label: string, value: string }[]
}

const DropdownMenuItem: React.FC<IToolsButton> = ({title, listDropdown}) => {
  const isDark = useThemeMode() === "dark";

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  const {styles, attributes} = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom-end',
    modifiers: [{name: 'offset', options: {offset: [0, 0]}}],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Box ref={setTargetRef} style={{
      padding: '4px 10px',
      borderRadius: '8px',
      margin: '0px 8px',
      backgroundColor: isOpen ? isDark ? palette?.LikeBlack : '#f2f5f9' : 'transparent',
      alignItems: 'center'
    }}>
      <Text color={isOpen ? 'brand' : 'primaryText'}
            style={{cursor: 'pointer',}}
            onTouchStart={() => {
              setIsOpen((s) => !s);
            }} size="small" weight="bold">
        {title}
        <div style={{marginLeft: '5px', display: 'inline-flex'}}>
          <svg
            width={10}
            height={6}
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={isOpen ? "M1.5 5L5 1.5L8.5 5" : "M8.5 1L5 4.5L1.5 1"}
              stroke={isOpen ? "#1AC486" : "#768597"}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

      </Text>
      {isOpen && (
        <div
          ref={setTooltipRef} {...attributes.popper}
          style={{...styles.popper, borderRadius: "0px", paddingTop: '16px', zIndex: 9999}}
        >
          <div style={{
            backgroundColor: isDark ? darkTheme.global.colors.backgroundDropdownItem : '#fff',
            display: "flex",
            flexDirection: "column",
            padding: '12px',
            borderRadius: '8px',
            boxShadow: '0px 8px 28px rgba(0, 0, 0, 0.2)'
          }}>
            {listDropdown.length ? listDropdown.map((item, index: number) => (
              <Link
                key={item?.value}
                style={{
                  textDecoration: "none",
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: isDark ? darkTheme.global.colors.primaryText : theme?.global?.colors.primaryText,
                  marginTop: index !== 0 ? '12px' : '0px'
                }}
                onClick={(e) => {
                  setIsOpen(false);
                }}
                to={item?.value}
              >
                {item?.label}
              </Link>
            )) : null}
          </div>

        </div>
      )}
    </Box>
  );
}

export default DropdownMenuItem
