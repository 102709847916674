import React, {useCallback, useEffect, useState} from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import {Box, Text} from "grommet";
import {ActionButton} from "./AbiMethodView";
import {useHistory} from "react-router-dom";

export const Wallet = (params: {
  onSetMetamask: (v: string) => void;
  onSetChainId: (chainId: number) => void;
}) => {
  const [metamaskAddress, setMetamask] = useState("");
  const [chainId, setChainId] = useState(0);

  useEffect(() => {
    params.onSetMetamask(metamaskAddress);
  }, [metamaskAddress]);

  useEffect(() => {
    params.onSetChainId(chainId);
  }, [chainId]);

  const signInMetamask = useCallback(() => {
    detectEthereumProvider().then((provider: any) => {
      try {
        // @ts-ignore
        if (provider !== window.ethereum) {
          console.error("Do you have multiple wallets installed?");
        }

        if (!provider) {
          alert("Metamask not found");
        }

        provider
          .request({method: "eth_chainId"})
          .then((chainId: string) => {
            setChainId(parseInt(chainId, 16));
          })
          .catch((error: any) => {
            console.error(
              `Error fetching chainId: ${error.code}: ${error.message}`
            );
          });

        provider.on("chainIdChanged", (chainId: string) =>
          setChainId(parseInt(chainId, 16))
        );
        provider.on("chainChanged", (chainId: string) =>
          setChainId(parseInt(chainId, 16))
        );
        provider.on("networkChanged", (chainId: string) =>
          setChainId(parseInt(chainId, 16))
        );

        provider.on("accountsChanged", (accounts: string[]) =>
          setMetamask(accounts[0])
        );

        provider.on("disconnect", () => {
          setMetamask("");
        });

        provider
          .request({method: "eth_requestAccounts"})
          .then(async (accounts: string[]) => {
            setMetamask(accounts[0]);
          });
      } catch (e) {
        console.error(e);
      }
    });
  }, []);

  const networkType = process.env.REACT_APP_NETWORK_TYPE
  const validChainId = parseInt(process.env.REACT_APP_NETWORK_CHAIN_ID as string) === chainId;
  const history = useHistory();


  return (
    <Box margin={{bottom: "medium"}}>
      {metamaskAddress ? (
        validChainId ? (
          <Box direction="row" gap="5px">
            User address:
            <Text
              size="small"
              style={{cursor: "pointer"}}
              onClick={() =>
                history.push(`/address/${metamaskAddress}`)
              }
              color="brand"
            >
              {metamaskAddress}
            </Text>
          </Box>
        ) : (
          <Box direction={"column"}>
            <Text size="small" color="errorText">
              Selected Metamask wallet network is unsupported. Please switch
              network to correct Posichain {networkType}
            </Text>
            <ActionButton
              style={{width: "250px"}}
              margin={{top: "small"}}
              onClick={() =>
                window.open(
                  "https://docs.posichain.org/",
                  "_blank"
                )
              }
            >
              Readme Posichain Metamask setup
            </ActionButton>
          </Box>
        )
      ) : (
        <Box width="200px">
          <ActionButton onClick={signInMetamask}>Sign in Metamask</ActionButton>
        </Box>
      )}
    </Box>
  );
};
