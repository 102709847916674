import { Box, Spinner, Text, TextInput } from "grommet";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "src/components/ui/Button";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { convertInputs } from "./helpers";
import { uniqid } from "src/pages/VerifyContract/VerifyContract";
import detectEthereumProvider from "@metamask/detect-provider";
import { useHistory } from "react-router-dom";
import { Address } from "../../../components/ui";
import Big from "big.js";

const Field = styled(Box)``;

const ViewWrapper = styled(Box)`
  border: 1px solid #e7ecf7;
  border-radius: 5px;
`;

const NameWrapper = styled(Box)`
  border-bottom: 1px solid #e7ecf7;
  padding: 10px;
  opacity: 0.7;
  border-radius: 5px;
`;

const SmallTextInput = styled(TextInput)`
  font-size: 14px;
  font-weight: 400;

  ::placeholder {
    font-size: 14px;
  }
`;

export const ActionButton = styled(Button)`
  font-size: 14px;
  padding: 7px 8px 5px 8px;
  font-weight: 500;
`;

const GreySpan = styled("span")`
  font-size: 14px;
  opacity: 0.7;
  font-weight: 400;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const GAS_LIMIT = 6721900;
const GAS_PRICE = 30000000000;

export const AbiMethodsView = (props: {
  abiMethod: AbiItem;
  address: string;
  metamaskAddress?: string;
  index: number;
  isRead?: boolean;
  validChainId?: boolean;
}) => {
  const { abiMethod, address, index } = props;
  const [inputsValue, setInputsValue] = useState<string[]>(
    [...new Array(abiMethod.inputs?.length)].map(() => "")
  );
  const [multipleValue, setMultipleValue] = useState({
    write: {},
    read: {},
  } as any);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [result, setResult] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const callEstGas = async (method: any, account: string, value?: string) => {
    try {
      console.log("run est gas");
      const gas = await method.estimateGas({ from: account, value });
      const newGas = Number(gas) * 1.5;
      return Math.floor(newGas);
    } catch (err: any) {
      throw err;
    }
  };

  const query = async () => {
    try {
      setError("");
      setResult([]);
      setLoading(true);

      // fix when there are multiple ethereum providers; detect it! returns actual provider
      const web3: any = await detectEthereumProvider();

      const web3URL = props.isRead
        ? process.env.REACT_APP_RPC_URL_SHARD0
        : web3
        ? web3
        : process.env.REACT_APP_RPC_URL_SHARD0;

      const hmyWeb3 = new Web3(web3URL);

      const contract = new hmyWeb3.eth.Contract([abiMethod], address);

      if (abiMethod.name) {
        let res;

        if (abiMethod.stateMutability === "view") {
          res = await contract.methods[abiMethod.name]
            .apply(contract, convertInputs(inputsValue, abiMethod.inputs || []))
            .call();
          setResult(
            Array.isArray(res)
              ? res
              : typeof res === "object"
              ? res
              : [res.toString()]
          );
        } else {
          // @ts-ignore
          const accounts = await ethereum.enable();

          const account = accounts[0] || undefined; // if function is not a view method it will require a signer
          const method = contract.methods[abiMethod.name].apply(
            contract,
            convertInputs(inputsValue, abiMethod.inputs || [])
          );
          const gasEst = await callEstGas(
            method,
            account,
            !!amount ? new Big(amount).times(new Big(1e18)).toFixed() : "0x0"
          );
          res = await method.send({
            gasLimit: gasEst,
            gasPrice: GAS_PRICE,
            from: account,
            value: !!amount
              ? new Big(amount).times(new Big(1e18)).toFixed()
              : "0x0",
          });
          setResult(
            Array.isArray(res)
              ? res
              : typeof res === "object"
              ? Object.values(res)
              : [res.toString()]
          );
        }
      }
    } catch (e) {
      console.log("e", e);
      // @ts-ignore
      setError(e.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      abiMethod.stateMutability !== "payable" &&
      (!abiMethod.inputs || !abiMethod.inputs.length) &&
      props.isRead
    ) {
      query();
    }
  }, [abiMethod, address]);

  const setInputValue = (value: string, idx: number) => {
    const newArr = inputsValue.map((v, i) => (i === idx ? value : v));
    setInputsValue(newArr);
  };
  const history = useHistory();

  return (
    <ViewWrapper direction="column" margin={{ bottom: "medium" }}>
      <NameWrapper background={"backgroundBack"}>
        <Text size="small">
          {index + 1}. {abiMethod.name}
        </Text>
      </NameWrapper>

      <Box pad="20px">
        {abiMethod.stateMutability === "payable" ? (
          <Field gap="5px">
            <Text size="small">
              payableAmount <span>POSI</span>
            </Text>
            <SmallTextInput
              value={amount}
              placeholder={`payableAmount (POSI)`}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setAmount(evt.currentTarget.value)
              }
            />
          </Field>
        ) : null}
        {abiMethod.inputs && abiMethod.inputs.length ? (
          <Box gap="12px">
            {abiMethod.inputs.map((input, idx) => {
              const name = input.name || "<input>";
              const isArrayValue = input.type.indexOf("[]") >= 0;
              const tabMethod = props.isRead ? "read" : "write";

              const itemValue = isArrayValue
                ? multipleValue[tabMethod][idx] || [{ value: "", id: "1" }]
                : inputsValue[idx];

              const itemType = input.type.slice(0, input.type.indexOf("[]"));

              return (
                <Field gap="5px">
                  <Text size="small">
                    {name} <span>({input.type})</span>
                  </Text>
                  {isArrayValue ? (
                    <Box direction={"column"}>
                      {itemValue.map(
                        (
                          item: { id: string; value: string },
                          itemId: number
                        ) => {
                          return (
                            <Box
                              direction={"row"}
                              align={"center"}
                              margin={"small"}
                            >
                              <Text style={{ marginRight: "10px" }}>
                                {itemId}.
                              </Text>
                              <SmallTextInput
                                key={item.id}
                                value={item.value}
                                placeholder={`${itemType}`}
                                onChange={(
                                  evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  itemValue[itemId].value =
                                    evt.currentTarget.value;

                                  setMultipleValue({
                                    ...multipleValue,
                                    [tabMethod]: {
                                      ...multipleValue[tabMethod],
                                      [idx]: itemValue,
                                    },
                                  });

                                  setInputValue(
                                    itemValue.map((t: any) => t.value),
                                    idx
                                  );
                                }}
                              />
                              {itemValue.length === 1 ? null : (
                                <ActionButton
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setMultipleValue({
                                      ...multipleValue,
                                      [tabMethod]: {
                                        ...multipleValue[tabMethod],
                                        [idx]: itemValue.filter(
                                          (removeItem: any) =>
                                            removeItem.id !== item.id
                                        ),
                                      },
                                    });
                                  }}
                                >
                                  remove
                                </ActionButton>
                              )}
                            </Box>
                          );
                        }
                      )}
                      <ActionButton
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          itemValue.push({ value: "", id: uniqid() });

                          setMultipleValue({
                            ...multipleValue,
                            [tabMethod]: {
                              ...multipleValue[tabMethod],
                              [idx]: itemValue,
                            },
                          });
                        }}
                      >
                        + add one more
                      </ActionButton>
                    </Box>
                  ) : (
                    <SmallTextInput
                      value={inputsValue[idx]}
                      placeholder={`${name} (${input.type})`}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(evt.currentTarget.value, idx);
                      }}
                    />
                  )}
                </Field>
              );
            })}
          </Box>
        ) : null}

        {!result ||
        abiMethod.inputs?.length ||
        abiMethod.stateMutability !== "view" ? (
          <Box width="100px" margin={{ top: "20px", bottom: "18px" }}>
            {loading ? (
              <Spinner />
            ) : abiMethod.stateMutability === "view" ? (
              <ActionButton onClick={query}>Query</ActionButton>
            ) : (
              <ActionButton
                disabled={!props.metamaskAddress || !props.validChainId}
                onClick={query}
              >
                Write
              </ActionButton>
            )}
          </Box>
        ) : null}

        {abiMethod.outputs
          ? abiMethod.outputs.map((input) => {
              return (
                <Box>
                  {typeof result === "object" || result.length ? (
                    <Text size="small">
                      <GreySpan>
                        {input.name}
                        {` (${input.type})`}
                      </GreySpan>{" "}
                      {": "}
                      {input.type.toString() === "address" ? (
                        <Address address={result.toString()} />
                      ) : (
                        <Text
                          size="small"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (input.type.toString() === "address") {
                              history.push(`/address/${result.toString()}`);
                            }
                          }}
                          color={
                            input.type.toString() === "address" ? "brand" : ""
                          }
                        >
                          {Array.isArray(result)
                            ? [result]
                            : typeof result === "object"
                            ? result[input.name]
                            : result.length === 1
                            ? [result]
                            : result.toString()}
                        </Text>
                      )}
                    </Text>
                  ) : (
                    <Text size="small">
                      <GreySpan>
                        {input.name}
                        {` (${input.type})`}
                      </GreySpan>{" "}
                      {": "}
                    </Text>
                  )}
                </Box>
              );
            })
          : null}

        {error && (
          <Text color="red" size="small" style={{ marginTop: 5 }}>
            {error}
          </Text>
        )}
      </Box>
    </ViewWrapper>
  );
};
