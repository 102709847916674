import React from "react";
import { Box, Button, Text } from "grommet";
import { Medium, Twitter } from "grommet-icons";
import styled, { CSSProperties } from "styled-components";

import { TelegramIcon } from "src/components/ui/icons";
import { BaseContainer } from "../ui";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../responsive/breakpoints";
import { useThemeMode } from "../../hooks/themeSwitcherHook";
import { handleAddNetWorkOfPOSI } from "../../utils";

const IconAhchor = styled.a`
  opacity: 0.9;
  transition: 0.17s ease all;

  &:hover {
    opacity: 1;
  }
`;

export function AppFooter(props: { style: CSSProperties }) {
  const isSmallerThan768 = useMediaQuery({
    maxDeviceWidth: breakpoints.tablet,
  });
  const isSmallerThan480 = useMediaQuery({ maxDeviceWidth: "480px" });
  return (
    <Box
      background="background"
      justify="center"
      align="center"
      pad="medium"
      margin={{ top: "medium" }}
      {...props}
    >
      <BaseContainer
        direction={isSmallerThan480 ? "column" : "row"}
        align={isSmallerThan480 ? "left" : "center"}
        justify="between"
        flex
      >
        <AddNetworkToMetamaskButton />
        {isSmallerThan480 && (
          <Box
            direction="row"
            width="fit-content"
            justify="center"
            align="center"
            gap="medium"
            style={{
              marginTop: isSmallerThan480 ? "10px" : "0px",
              marginBottom: isSmallerThan480 ? "6px" : "0px",
            }}
          >
            <IconAhchor
              href="https://positionex.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Medium
                size="23px"
                color="minorText"
                style={{ cursor: "pointer" }}
              />
            </IconAhchor>
            <IconAhchor
              href="https://t.me/PositionExchange"
              target="_blank"
              rel="noreferrer"
            >
              <TelegramIcon size="22px" color="minorText" />
            </IconAhchor>
            <IconAhchor
              href="https://twitter.com/PositionEx"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter
                size="24px"
                color="minorText"
                style={{ cursor: "pointer" }}
              />
            </IconAhchor>
          </Box>
        )}
        <Box
          direction={
            isSmallerThan480 ? "row" : isSmallerThan768 ? "column" : "row"
          }
          justify={isSmallerThan480 ? "start" : "center"}
          align={isSmallerThan480 ? "start" : "center"}
          gap="xsmall"
        >
          <Text
            color="minorText"
            size="xsmall"
            style={{ alignItems: "center" }}
          >
            <Text
              as={"span"}
              color="minorText"
              size="xsmall"
              margin={{ top: "3px" }}
            >
              ©
            </Text>{" "}
            Posichain {new Date().getFullYear()}{" "}
            <Text
              hidden={isSmallerThan768 && !isSmallerThan480}
              as={"span"}
              color="minorText"
              size="small"
            >
              ·
            </Text>
          </Text>

          <Text
            color="minorText"
            size="xsmall"
            style={{ alignItems: "center", lineHeight: "20px" }}
          >
            contact@posichain.org
          </Text>
        </Box>
        <Box
          style={{ display: isSmallerThan480 ? "none" : "flex" }}
          direction="row"
          width="fit-content"
          justify="center"
          align="center"
          gap="medium"
        >
          <IconAhchor
            href="https://positionex.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Medium
              size="23px"
              color="minorText"
              style={{ cursor: "pointer" }}
            />
          </IconAhchor>
          <IconAhchor
            href="https://t.me/PositionExchange"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon size="22px" color="minorText" />
          </IconAhchor>
          <IconAhchor
            href="https://twitter.com/PositionEx"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter
              size="24px"
              color="minorText"
              style={{ cursor: "pointer" }}
            />
          </IconAhchor>
        </Box>
      </BaseContainer>
    </Box>
  );
}

const AddNetworkToMetamaskButton = () => {
  const themeMode = useThemeMode();
  return (
    <Button
      style={{
        background:
          themeMode === "dark" ? "rgba(123, 192, 255, 0.12)" : "#F2F5F9",
        padding: "8px",
        borderRadius: "8px",
        alignItems: "center",
        width: "fit-content",
      }}
      onClick={handleAddNetWorkOfPOSI}
    >
      <Box
        style={{
          alignItems: "center",
          flexDirection: "row",
          width: "fit-content",
        }}
      >
        <MetamaskIcon />
        <Text
          size={"small"}
          color={"buttonText"}
          style={{ marginLeft: "4px", width: "fit-content" }}
        >
          Add POSI Network
        </Text>
      </Box>
    </Button>
  );
};

const MetamaskIcon = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_366_13047)">
      <mask id="path-1-inside-1_366_13047" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.39844 16.25L8.59243 16.8304V16.0714L8.77152 15.8928H10.0252V16.7857V17.4107H8.68198L7.02528 16.6964L6.39844 16.25Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39844 16.25L8.59243 16.8304V16.0714L8.77152 15.8928H10.0252V16.7857V17.4107H8.68198L7.02528 16.6964L6.39844 16.25Z"
        fill="#CDBDB2"
      />
      <path
        d="M6.39844 16.25L6.78205 14.7998L5.52828 17.4718L6.39844 16.25ZM8.59243 16.8304L8.20881 18.2805L10.0924 18.7788V16.8304H8.59243ZM8.59243 16.0714L7.53333 15.0092L7.09243 15.4488V16.0714H8.59243ZM8.77152 15.8928V14.3928H8.1515L7.71243 14.8306L8.77152 15.8928ZM10.0252 15.8928H11.5252V14.3928H10.0252V15.8928ZM10.0252 17.4107V18.9107H11.5252V17.4107H10.0252ZM8.68198 17.4107L8.0881 18.7881L8.37239 18.9107H8.68198V17.4107ZM7.02528 16.6964L6.15512 17.9182L6.28499 18.0107L6.4314 18.0738L7.02528 16.6964ZM6.01482 17.7001L8.20881 18.2805L8.97604 15.3802L6.78205 14.7998L6.01482 17.7001ZM10.0924 16.8304V16.0714H7.09243V16.8304H10.0924ZM9.65153 17.1336L9.83062 16.955L7.71243 14.8306L7.53333 15.0092L9.65153 17.1336ZM8.77152 17.3928H10.0252V14.3928H8.77152V17.3928ZM8.52521 15.8928V16.7857H11.5252V15.8928H8.52521ZM8.52521 16.7857V17.4107H11.5252V16.7857H8.52521ZM10.0252 15.9107H8.68198V18.9107H10.0252V15.9107ZM9.27586 16.0333L7.61916 15.319L6.4314 18.0738L8.0881 18.7881L9.27586 16.0333ZM7.89544 15.4746L7.2686 15.0282L5.52828 17.4718L6.15512 17.9182L7.89544 15.4746Z"
        fill="white"
        mask="url(#path-1-inside-1_366_13047)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.562 16.25L11.4128 16.8304V16.0714L11.2337 15.8928H9.98006V16.7857V17.4107H11.3233L12.9799 16.6964L13.562 16.25Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77138 14.5981L8.59229 16.0713L8.81612 15.8927H11.1444L11.413 16.0713L11.2339 14.5981L10.8757 14.3749L9.08476 14.4196L8.77138 14.5981Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5625 4.64282L8.63709 7.14282L9.12965 14.4196H10.8758L11.4131 7.14282L12.3981 4.64282H7.5625Z"
        fill="#F89C35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86111 10.1338L1.60742 13.7499L4.74164 13.5713H6.75645V12.0088L6.6669 8.79456L6.21915 9.1517L2.86111 10.1338Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23438 10.5357L8.90588 10.625L8.50295 12.5L6.75671 12.0536L5.23438 10.5357Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23438 10.5803L6.75671 12.0089V13.4375L5.23438 10.5803Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75684 12.0536L8.54781 12.5001L9.12992 14.4198L8.72691 14.6429L6.75684 13.4822V12.0536Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75663 13.4821L6.39844 16.2499L8.77152 14.5982L6.75663 13.4821Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90575 10.625L9.12967 14.4197L8.45801 12.4777L8.90575 10.625Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69727 13.5267L6.75689 13.4821L6.3987 16.2499L4.69727 13.5267Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59246 17.4554L6.39825 16.25L4.69682 13.5268L1.60742 13.75L2.59246 17.4554Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63724 7.14288L6.71197 8.75003L5.23438 10.5357L8.90588 10.6697L8.63724 7.14288Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39844 16.2499L8.77152 14.5981L8.59243 16.0267V16.8303L6.98055 16.5177L6.39844 16.2499Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.562 16.2499L11.2337 14.5981L11.4128 16.0267V16.8303L13.0247 16.5177L13.562 16.2499Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92053 11.4285L8.41309 12.4554L6.66684 12.0089L7.92053 11.4285Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54785 2.54474L8.63718 7.14291L7.60741 4.64291L2.54785 2.54474Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54764 2.54474L1.7417 5.0001L2.18944 7.67867L1.87602 7.85724L2.32377 8.25902L1.96557 8.57152L2.45809 9.01795L2.14467 9.28581L2.86106 10.1787L6.21915 9.15188C7.86088 7.84236 8.66682 7.17271 8.63697 7.14295C8.60712 7.11319 6.57734 5.58045 2.54764 2.54474Z"
        fill="#8E5A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0998 10.1339L18.3535 13.7499L15.2193 13.5714H13.2045V12.0089L13.294 8.79462L13.7418 9.15176L17.0998 10.1339Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7271 10.5357L11.0555 10.625L11.4585 12.5L13.2047 12.0536L14.7271 10.5357Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7271 10.5803L13.2047 12.0089V13.4375L14.7271 10.5803Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2046 12.0536L11.4136 12.5001L10.8315 14.4198L11.2345 14.6429L13.2046 13.4822V12.0536Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2048 13.4822L13.563 16.25L11.2347 14.6429L13.2048 13.4822Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0552 10.625L10.8313 14.4197L11.5029 12.4777L11.0552 10.625Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2642 13.5269L13.2045 13.4822L13.5627 16.25L15.2642 13.5269Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3685 17.4554L13.5627 16.25L15.2641 13.5268L18.3535 13.75L17.3685 17.4554Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3242 7.14288L13.2495 8.75003L14.7271 10.5357L11.0555 10.6697L11.3242 7.14288Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0404 11.4286L11.5479 12.4554L13.2941 12.009L12.0404 11.4286Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4131 2.54486L11.3238 7.14304L12.3535 4.64304L17.4131 2.54486Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4133 2.54474L18.2192 5.0001L17.7715 7.67867L18.0849 7.85724L17.6372 8.25902L17.9954 8.57152L17.5028 9.01795L17.8163 9.28581L17.0999 10.1787L13.7418 9.15188C12.1001 7.84236 11.2941 7.17271 11.324 7.14295C11.3538 7.11319 13.3836 5.58045 17.4133 2.54474Z"
        fill="#8E5A30"
      />
    </g>
    <defs>
      <clipPath id="clip0_366_13047">
        <rect
          width={16.875}
          height={15}
          fill="white"
          transform="translate(1.5625 2.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
