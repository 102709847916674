import React from "react";
import {Box} from "grommet";
import styled from "styled-components";
import Switch from "react-switch";
import {setThemeMode, themeType, useThemeMode,} from "src/hooks/themeSwitcherHook";

import {DateFormat, useDateFormatMode} from "src/hooks/dateFormatSwitcherHook";

import {currencyType,} from "src/hooks/POSI-ETH-SwitcherHook";

export function ConfigureButton() {
  const theme = useThemeMode();
  const dateFormat = useDateFormatMode();

  const isChecked = theme === 'dark'


  const handleChange = (e: any) => {
    if (e) {
      setThemeMode('dark')
    } else {
      setThemeMode('light')
    }
  }
  return (
    <Box style={{
      width: 'fit-content',
    }}>
      <Switch
        checked={isChecked}
        onChange={handleChange}
        handleDiameter={0}
        offColor="#f2f5f9"
        onColor="#10141A"
        offHandleColor="#fff"
        onHandleColor="#3D495C"
        height={35}
        width={70}
        borderRadius={40}
        activeBoxShadow="0px 0px 1px 2px transparent"
        uncheckedIcon={
          <div>
          </div>
        }
        checkedIcon={
          <div>
          </div>
        }
        uncheckedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 20
            }}
          >
            <svg
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 12.25C10.3472 12.25 12.25 10.3472 12.25 8C12.25 5.65279 10.3472 3.75 8 3.75C5.65279 3.75 3.75 5.65279 3.75 8C3.75 10.3472 5.65279 12.25 8 12.25Z"
                fill="#ECC94B"
              />
              <path
                d="M8 2.75C8.13261 2.75 8.25979 2.69732 8.35355 2.60355C8.44732 2.50979 8.5 2.38261 8.5 2.25V1C8.5 0.867392 8.44732 0.740215 8.35355 0.646447C8.25979 0.552678 8.13261 0.5 8 0.5C7.86739 0.5 7.74021 0.552678 7.64645 0.646447C7.55268 0.740215 7.5 0.867392 7.5 1V2.25C7.5 2.38261 7.55268 2.50979 7.64645 2.60355C7.74021 2.69732 7.86739 2.75 8 2.75Z"
                fill="#ECC94B"
              />
              <path
                d="M3.58125 4.2875C3.67576 4.3795 3.80246 4.43099 3.93437 4.43099C4.06628 4.43099 4.19298 4.3795 4.2875 4.2875C4.381 4.19376 4.43351 4.06677 4.43351 3.93437C4.43351 3.80197 4.381 3.67498 4.2875 3.58125L3.40625 2.69375C3.36141 2.63912 3.30564 2.59449 3.24252 2.56272C3.1794 2.53095 3.11033 2.51274 3.03975 2.50928C2.96917 2.50581 2.89865 2.51716 2.83272 2.54259C2.76679 2.56802 2.70691 2.60698 2.65694 2.65694C2.60698 2.70691 2.56802 2.76679 2.54259 2.83272C2.51716 2.89865 2.50581 2.96917 2.50928 3.03975C2.51274 3.11033 2.53095 3.1794 2.56272 3.24252C2.59449 3.30564 2.63912 3.36141 2.69375 3.40625L3.58125 4.2875Z"
                fill="#ECC94B"
              />
              <path
                d="M2.75 8C2.75 7.86739 2.69732 7.74021 2.60355 7.64645C2.50979 7.55268 2.38261 7.5 2.25 7.5H1C0.867392 7.5 0.740215 7.55268 0.646447 7.64645C0.552678 7.74021 0.5 7.86739 0.5 8C0.5 8.13261 0.552678 8.25979 0.646447 8.35355C0.740215 8.44732 0.867392 8.5 1 8.5H2.25C2.38261 8.5 2.50979 8.44732 2.60355 8.35355C2.69732 8.25979 2.75 8.13261 2.75 8Z"
                fill="#ECC94B"
              />
              <path
                d="M3.5813 11.7125L2.6938 12.5937C2.59991 12.6885 2.54724 12.8166 2.54724 12.95C2.54724 13.0834 2.59991 13.2114 2.6938 13.3062C2.79046 13.3969 2.91753 13.4482 3.05005 13.45C3.18282 13.4494 3.31033 13.398 3.4063 13.3062L4.28755 12.4187C4.37373 12.3236 4.42004 12.199 4.41688 12.0707C4.41372 11.9424 4.36134 11.8202 4.27058 11.7295C4.17983 11.6387 4.05765 11.5863 3.92934 11.5832C3.80103 11.58 3.67642 11.6263 3.5813 11.7125Z"
                fill="#ECC94B"
              />
              <path
                d="M8 13.25C7.86739 13.25 7.74021 13.3027 7.64645 13.3964C7.55268 13.4902 7.5 13.6174 7.5 13.75V15C7.5 15.1326 7.55268 15.2598 7.64645 15.3536C7.74021 15.4473 7.86739 15.5 8 15.5C8.13261 15.5 8.25979 15.4473 8.35355 15.3536C8.44732 15.2598 8.5 15.1326 8.5 15V13.75C8.5 13.6174 8.44732 13.4902 8.35355 13.3964C8.25979 13.3027 8.13261 13.25 8 13.25Z"
                fill="#ECC94B"
              />
              <path
                d="M12.4187 11.7125C12.3236 11.6263 12.199 11.58 12.0707 11.5832C11.9424 11.5863 11.8202 11.6387 11.7295 11.7295C11.6387 11.8202 11.5863 11.9424 11.5832 12.0707C11.58 12.199 11.6263 12.3236 11.7125 12.4187L12.5937 13.3062C12.6897 13.398 12.8172 13.4494 12.95 13.45C13.0825 13.4482 13.2096 13.3969 13.3062 13.3062C13.4001 13.2114 13.4528 13.0834 13.4528 12.95C13.4528 12.8166 13.4001 12.6885 13.3062 12.5937L12.4187 11.7125Z"
                fill="#ECC94B"
              />
              <path
                d="M15 7.5H13.75C13.6174 7.5 13.4902 7.55268 13.3964 7.64645C13.3027 7.74021 13.25 7.86739 13.25 8C13.25 8.13261 13.3027 8.25979 13.3964 8.35355C13.4902 8.44732 13.6174 8.5 13.75 8.5H15C15.1326 8.5 15.2598 8.44732 15.3536 8.35355C15.4473 8.25979 15.5 8.13261 15.5 8C15.5 7.86739 15.4473 7.74021 15.3536 7.64645C15.2598 7.55268 15.1326 7.5 15 7.5Z"
                fill="#ECC94B"
              />
              <path
                d="M12.0688 4.43128C12.1338 4.43185 12.1982 4.41943 12.2583 4.39475C12.3184 4.37006 12.373 4.33361 12.4188 4.28753L13.3063 3.40628C13.386 3.30913 13.4268 3.1858 13.4206 3.06027C13.4144 2.93475 13.3618 2.816 13.2729 2.72713C13.1841 2.63827 13.0653 2.58563 12.9398 2.57947C12.8143 2.5733 12.6909 2.61405 12.5938 2.69378L11.7125 3.58128C11.619 3.67501 11.5665 3.80201 11.5665 3.9344C11.5665 4.0668 11.619 4.1938 11.7125 4.28753C11.7591 4.33437 11.8147 4.37126 11.8759 4.39598C11.9372 4.42069 12.0028 4.4327 12.0688 4.43128Z"
                fill="#ECC94B"
              />
            </svg>
          </div>
        }
        checkedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "red",
              fontSize: 18
            }}
          >
            <svg
              width={13}
              height={12}
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.09996 8.19996C5.91662 8.19996 8.19997 5.91661 8.19997 3.09996C8.19997 1.93466 7.80914 0.860641 7.15142 0.00183105C10.3952 0.0821769 13 2.73687 13 5.99996C13 9.31367 10.3137 12 7 12C4.34017 12 2.08457 10.2692 1.29797 7.87247C1.85826 8.08413 2.46558 8.19996 3.09996 8.19996Z"
                fill="#F2F5F9"
              />
              <path
                d="M4.48338 1.69583C4.46327 1.6302 4.42451 1.57241 4.37218 1.53003C4.31985 1.48765 4.2564 1.46266 4.19015 1.45833L2.99714 1.37292L2.55528 0.216667C2.53115 0.153001 2.48916 0.0983133 2.43473 0.0596842C2.3803 0.021055 2.31595 0.000263101 2.25 0C2.18405 0.000263101 2.1197 0.021055 2.06527 0.0596842C2.01084 0.0983133 1.96885 0.153001 1.94472 0.216667L1.49483 1.37917L0.309855 1.45833C0.243678 1.46294 0.180355 1.48803 0.128079 1.53037C0.0758016 1.57271 0.0369758 1.63035 0.0166238 1.69583C-0.00427576 1.76232 -0.00549685 1.83377 0.013118 1.90098C0.0317329 1.96819 0.0693302 2.02808 0.121062 2.07292L1.03289 2.87292L0.761752 3.97917C0.742993 4.054 0.746369 4.13296 0.771441 4.20579C0.796514 4.27861 0.842123 4.34192 0.902342 4.3875C0.960794 4.43102 1.03043 4.45545 1.10235 4.45768C1.17427 4.45991 1.24519 4.43982 1.30604 4.4L2.24799 3.78125H2.25201L3.26627 4.44583C3.31829 4.48091 3.37895 4.49971 3.441 4.5C3.49166 4.49959 3.54156 4.48713 3.58685 4.46359C3.63215 4.44004 3.67164 4.40604 3.70229 4.36419C3.73293 4.32234 3.75392 4.27376 3.76364 4.22218C3.77335 4.17061 3.77154 4.1174 3.75833 4.06667L3.47113 2.85625L4.37894 2.07292C4.43067 2.02808 4.46827 1.96819 4.48688 1.90098C4.5055 1.83377 4.50428 1.76232 4.48338 1.69583Z"
                fill="#F2F5F9"
              />
            </svg>
          </div>
        }
        className="react-switch"
        id="small-radius-switch"
      /></Box>
    // <DropButton
    //   label={<GearIcon size="24px" color={'#fff'}/>}
    //   dropAlign={{top: "bottom", right: "right"}}
    //   style={{
    //     height: '32px',
    //     border: "none",
    //     boxShadow: "none",
    //     paddingRight: "6px",
    //     paddingLeft: 0,
    //   }}
    //   dropProps={{round: '4px'}}
    //   dropContent={
    //     <Box
    //       pad="medium"
    //       background="background"
    //       border={{size: "xsmall", color: "border"}}
    //       style={{borderRadius: "0px"}}
    //     >
    //       <Text size="small" weight="bold" margin={{bottom: "xsmall"}}>
    //         Theme
    //       </Text>
    //       <ToggleButton
    //         value={theme}
    //         options={[
    //           {text: "Light", value: "light"},
    //           {text: "Dark", value: "dark"},
    //         ]}
    //         onChange={setThemeMode}
    //       />
    //     </Box>
    //   }
    // />
  );
}

interface ToggleProps {
  value: string;
  options: Array<{
    text: string;
    value: themeType | currencyType | DateFormat;
  }>;
  onChange: (value: any) => void;
}

//@ts-ignore
const ToggleButton = (props: ToggleProps) => {
  const {options, value, onChange} = props;

  return (
    <Box
      direction="row"
      background="transparent"
      border={{size: "xsmall", color: "border"}}
      style={{overflow: "hidden", borderRadius: "8px"}}
    >
      {options.map((i) => (
        <SwitchButton
          selected={i.value === value}
          onClick={() => onChange(i.value)}
          key={i.value}
        >
          {i.text}
        </SwitchButton>
      ))}
    </Box>
  );
};

const SwitchButton = styled.div<{ selected: boolean }>`
  padding: 8px 20px;
  min-width: 60px;
  background-color: ${(props) =>
          props.selected ? props.theme.global.colors.brand : "transparent"};
  color: ${(props) =>
          props.selected
                  ? props.theme.global.colors.background
                  : props.theme.global.colors.brand};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  user-select: none;
  outline: none;
  text-align: center;
  cursor: ${(props) => (props.selected ? "auto" : "pointer")};
`;
