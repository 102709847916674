import React, {useEffect} from "react";
import dayjs from "dayjs";

export function POSI_USDT_Rate() {
  useEffect(() => {
    const getRates = () => {
      const rates = {} as Record<string, number>;
      fetch(`${process.env.REACT_APP_EXCHANGE_API_URL}statistics/POSI/Kline`)
        .then((_res) => _res.json())
        .then((res) => {
          const data = res?.data?.data || []
          data.forEach((t: Array<string | number>) => {
            rates[String(t[0])] = Number(t[1]);
          });
          window.localStorage.setItem('POSI_USDT_rates', JSON.stringify(rates))
        });
    };
    getRates();
    const tId = window.setInterval(getRates, 10 * 60 * 1e3);

    return () => {
      clearTimeout(tId);
    }
  }, []);
  return null;
}

export function getNearestPriceForTimestamp(timestampString: string) {
  const rates = JSON.parse(window.localStorage.getItem('POSI_USDT_rates') || '{}') as Record<string, number>;
  const timestamps = Object.keys(rates);
  const prices = Object.values(rates);
  const timestamp = dayjs(timestampString).valueOf();

  if (timestamp >= +timestamps.slice(-1)[0]) {
    return prices.slice(-1)[0];
  }

  if (timestamp <= +timestamps[0]) {
    return -1;
  }

  if (timestamps.length) {
    let i = 0;
    while (+timestamps[i] <= timestamp) {
      i++;
    }

    return prices[i];
  }

  return 0;
}
