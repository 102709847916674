import dayjs from "dayjs";
import {
  RelatedTransaction,
  RelatedTransactionType,
  RPCTransactionPosiChain,
  TransactionExtraMark,
} from "../types";
import { getAddress } from "./getAddress/GetAddress";
import { bridgeTokensMap } from "src/config";

export const getQueryVariable = (variable: string, query: string) => {
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

export const isTokenBridged = (address: string) => !!bridgeTokensMap[address];

export const copyTextToClipboard = (value: string) => {
  const copyTextareaInput = document.createElement("textarea");
  copyTextareaInput.value = value;
  document.body.appendChild(copyTextareaInput);

  copyTextareaInput.focus();
  copyTextareaInput.select();

  try {
    document.execCommand("copy");
  } catch {
  } finally {
    document.body.removeChild(copyTextareaInput);
  }
};

export const mapBlockchainTxToRelated = (
  tx: RPCTransactionPosiChain,
  type: RelatedTransactionType = "transaction"
): RelatedTransaction => {
  const resultedTx = {
    ...tx,
    transactionType: type,
    address: "",
    transactionHash: tx.ethHash || tx.hash,
    timestamp: dayjs(+tx.timestamp * 1000).toString(),
    extraMark: TransactionExtraMark.normal,
  };
  if (tx.from) {
    resultedTx.from = getAddress(tx.from).basicHex;
  }
  if (tx.to) {
    resultedTx.to = getAddress(tx.to).basicHex;
  }
  if (typeof tx.value !== "undefined") {
    resultedTx.value = BigInt(tx.value).toString();
  }
  return resultedTx;
};

export const pushHistoryHex = (history: any, value: string, type: string) => {
  switch (type) {
    case "address":
    case "tx":
    case "number":
      history.push(`/${type}/${value}`);
      break;
    default:
      break;
  }
};

export const handleAddNetWorkOfPOSI = async () => {
  const configNetWork: { [key: string]: { [key: string]: string } } = {
    devnet: {
      netWorkUrl: "https://api.s0.d.posichain.org/",
      chainID: "920000",
      explorerUrl: "https://explorer-devnet.posichain.org/",
      chainName: "POSI Chain Devnet",
    },
    testnet: {
      netWorkUrl: "https://api.s0.t.posichain.org/",
      chainID: "910000",
      explorerUrl: "https://explorer-testnet.posichain.org/",
      chainName: "POSI Chain Testnet",
    },
    mainnet: {
      netWorkUrl: "https://api.posichain.org/",
      chainID: "900000",
      explorerUrl: "https://explorer.posichain.org/",
      chainName: "POSI Chain Mainnet",
    },
  };

  const env =
    process.env.REACT_APP_NETWORK_TYPE?.toLowerCase() || ("testnet" as string);

  const data = configNetWork[env];

  const dataParams = [
    {
      chainId: `0x${Number(data?.chainID).toString(16)}`,
      chainName: data?.chainName,
      nativeCurrency: {
        name: "Position Exchange",
        symbol: "POSI",
        decimals: 18,
      },
      rpcUrls: [data?.netWorkUrl],
      iconUrls: ["https://position.exchange/favicon.ico"],
      blockExplorerUrls: [data?.explorerUrl],
    },
  ];

  return (
    window.ethereum &&
    (window.ethereum as any).request({
      method: "wallet_addEthereumChain",
      params: dataParams,
    })
  );
};
